import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import VueCookies from 'vue-cookies'
import { iconsSet as icons } from '@/assets/icons'

const vueConfig = require('vue-config')
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookies)
// set default config
app.$cookies.config('2d')

const configs = {
  API: 'https://api.ruvents.com/',
  //API: 'http://localhost:11018/',
}

app.use(vueConfig, configs)

app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
